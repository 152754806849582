.spantotal{
    display:block;
    width:100%;
    height:40px;
    line-height:40px;
    color: rgb(144, 147, 153);
}
.spanlabel{
    display:inline-block;
    width:30%;
    height:35px;
    line-height:33px;
    text-align: center;
    border:1px solid #ccc;
    border-right:0;
    border-radius: 4px 0 0 4px;
    background: rgb(245, 247, 250);
}
.spaninput{
    width:68%;
    height:35px;
    border:1px solid #ccc;
    border-radius: 0 4px 4px 0;
    outline: none;
    text-indent: 10px;
}
.imgpic .avatar-uploader{
    width:23%;
    height: 120px;
    margin-right: 1%;
}
.imgpic .ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
    height: 100%;
}
 .ant-upload.ant-upload-select-picture-card{
    width: 100% !important;
}
.ant-upload img{
    width: 100% !important;
    height: 105px;
}
.avatar-uploader{
    float:left;
}
.ant-carousel .slick-slide {
    text-align: center;
    height: 400px;
    line-height: 400px;
    background: #364d79;
    overflow: hidden;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}
.spanlabel1{
    display:inline-block;
    width:25%;
    height:35px;
    line-height:33px;
    text-align: center;
    border:1px solid #ccc;
    border-right:0;
    border-radius: 4px 0 0 4px;
    background: rgb(245, 247, 250);
    cursor: pointer;
}
.spaninp{
    width: 180px;
    height:35px;
    border:1px solid #ccc;
    border-radius: 3px;
    outline: none;
    text-indent: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.partnamediv{
    position: absolute;
    left: 30%;
    background: #fff;
    width: 68%;
    height: 350px;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
}
.ant-select-selection{
    border: none!important;
}
.ant-select-selection__rendered{
    line-height: 33px;
}
.tablebottom .ant-table-wrapper{
    margin-bottom: 20px;
}
.YZMshow{
    display:inline-block;
}
.YZMnone{
    display:none;
}
.YZMtimershow{
    display: inline-block;
}
.YZMtimernone{
    display: none;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    text-align: center;
    padding:10px 5px!important;
}
.priceflex .ant-list-item{
    display: block;
    padding: 0 ;
}
.priceflex .ant-list-split .ant-list-item{
    border-bottom: none;
}
.loadpric{
    width:100vw;
    height:100vw;
    position: absolute;
    left:50%;
    top:50%;
}
.loadpric .anticon svg{
    font-size: 40px;
}
/* 序号列 开始*/
.item-skuoe {
  width: 10%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
/* 商品图片 */
.table-body .priceimg {
    width: 180px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}
.priceimg .priceimg-bottom {
  display: flex;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  background: rgba(0, 0, 0, .5)
}
.priceimg .priceimg-bottom p {
    width: 50%;
    text-align: center;
    font-size: 10px;
    cursor: pointer;
    color: #fff;
}
/* 表格主体 */
.table-body {
    display: flex;
}
/* 表格推荐价 开始 */
.table-body .body-right {
    width: 14%;
    background: rgb(212, 240, 255);
}
.table-body .body-right  .top-item {
    text-align: center;
}
.body-right-content {
    border-top: 1px solid #efefef;
}
.body-right-content .body-item {
    width: 100%;
}
/* 表格推荐价 结束 */
.table-row {
    width: 100%;
    display: block;
    border-bottom: 1px solid #efefef;
    padding-top: 5px;
    padding-bottom: 5px;
}
.table-row .body-item span {
    color: rgba(0, 0, 0, 0.6);
}

.table-body .body-left .top-item, .body-left .body-item  {
    width: 16.666%;
    display: inline-block;
    text-align: center;
}
.item-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-wrapper .item-col {
  width: 100px;
  display: flex;
  justify-content: center;
}
/* 调货按钮 */
.priceflex .top-btn {
  float: right; 
  line-height: 41px; 
  padding-right: 10px;
}